import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import * as jwt_decode from 'jwt-decode';

import { KEY_STORAGE } from '../enums/storage.enum';
import { DataToken } from '../../core/models/data-token.interface';
import { LocalStorageService } from './local-storage.service';
import { URL } from '../../core/configs/url';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TokenManageService {

	private _isRefreshing: Boolean = false;
	private _expiresToken: number = 3600;

	private localStorageService = inject(LocalStorageService);
	private httpClient = inject(HttpClient);
	private userService = inject(UserService);
	private router = inject(Router);

	set isRefreshing(value: Boolean) { this._isRefreshing = value; }
	get isRefreshing() { return this._isRefreshing; }

	set expiresToken(value: number) { this._expiresToken = value; }
	get expiresToken() { return this._expiresToken; }

	/** Realiza a gravação do objeto data token retornado da API no local storage*/
	setDataToken(dataToken: DataToken): void{
		this.localStorageService.setItem(KEY_STORAGE.DATA_TOKEN, dataToken)
	}

	/** Retorna o objeto data token que esta armazenado no local storage */
	getDataToken() {
		return this.localStorageService.getItem<DataToken>(KEY_STORAGE.DATA_TOKEN)!;
	}

	/** Apaga o objeto data token no local storare */
	clearToken(): void {
		this.localStorageService.removeItem(KEY_STORAGE.DATA_TOKEN)
		this.localStorageService.removeItem(KEY_STORAGE.LOGIN)
	}

	/** Adiciona o token nas requisições de API através da chamada dos interceptors */
	addTokenHeader(request: HttpRequest<unknown>) {
		const oauth = this.getDataToken();
		const token = oauth.access_token

		return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
	}

	/** Método responsável por realizar chamada da geração de um novo token partir do refresh token */
	refreshToken() {
		const dataToken = this.getDataToken();
		return this.httpClient.post<DataToken>(`${URL.token}?grant_type=REFRESH_TOKEN&refresh_token=${dataToken.refresh_token}`, '{}')
	}

	/** Verifica se o token esta expirado através do calculo de data de expiração do Token */
	isTokenExpired(token: string): boolean {
		/** Se não tiver token então considera como expirado */
		if (!token){
			return true;
		}

		/**  Verifica a data de expiração do Token*/
		const date = this.getTokenExpirationDate(token);
		const date2 = this.getTokenExpirationDate(token, 3);

		let dateTokenExpires = new Date(0)
		let dateRefreshExpires = new Date(0)
		let dateNow = new Date(0)

		if (date === undefined || date2 === undefined ){
			return true;
		} else {
			dateTokenExpires = new Date(date.valueOf())
			dateRefreshExpires = new Date(date2.valueOf())
			dateNow = new Date(Date.now())
		}

		let tokenIsExpires = ! (dateTokenExpires > dateNow)
		let refreshIsExpires = dateNow > dateRefreshExpires

		/** Se a data de expiração do Token for maior que a data atual então retorna que não esta expirado. 
		 *  Caso contrário realiza a chamada do método de getUser apenas para forçar ao interceptor validar o token e chamar o refresh token */
		if (! tokenIsExpires){
			return false;

		/** Se ja tiver mais de 2 horas vencido então redireciona para a tela de login*/
		} else if (refreshIsExpires){
			this.clearToken();
			this.router.navigate(['login']);
			
		} else {
			this.userService.getUser()
		}

		return false;
	}
	
	/** Verifica e retorna a data de expiração do Token */
	getTokenExpirationDate(token: string, multiple: number = 1): any {
		const decodeToken = jwt_decode.jwtDecode(token)

		if(decodeToken.exp === undefined){
			return null;
		}

		const date = new Date(0);
		date.setUTCSeconds(decodeToken.exp + (this.expiresToken * multiple));
		return date;
	}
}