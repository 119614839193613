import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { PoPageLogin } from '@po-ui/ng-templates';
import { ProAppConfigService } from '@totvs/protheus-lib-core';

import { DataToken } from '../../core/models/data-token.interface';
import { TokenManageService } from './token-manager.service';
import { URL } from '../../core/configs/url';
import { LocalStorageService } from './local-storage.service';
import { KEY_STORAGE } from '../enums/storage.enum';
import { UserService } from './user.service';
import { AdvertisementsService } from './advertisements.service';
import { CurriculumService } from '../../pages/curriculum/services/curriculum.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

    private _login: string = "";
    private authenticatedUser: boolean = false;

    showMenuEmitter = new EventEmitter<boolean>();
    languageChangeEmitter = new EventEmitter<string>();

    constructor( private httpClient: HttpClient,
                 private proAppConfigService: ProAppConfigService,
                 private tokenManagerService: TokenManageService,
                 private localStorageService: LocalStorageService,
                 private router: Router,
                 private userService: UserService,
                 private advertisementsService: AdvertisementsService,
                 private curriculumService: CurriculumService
            ) {}

    set login(login: string){
        this.localStorageService.setItem(KEY_STORAGE.LOGIN, login);
        this._login = login;
    }

    get login(){
        if (this._login === ''){
            let login = this.localStorageService.getItem<string>(KEY_STORAGE.LOGIN);

            if (login){
                this._login = login
            }
        }
        return this._login;
    }

    /** Realiza a chamada da API de autenticação do usuário */
    loginSubmit(login: PoPageLogin): Observable<DataToken>{
        return this.httpClient.post<DataToken>(`${URL.token}?grant_type=PASSWORD`, `{"user":"${login.login}","password":"${encodeURIComponent(login.password)}"}`)
    }
   
    /** Verifica se o usuário ainda esta logado, ou seja se o token ainda é valido */
    isUserLoggedIn(): boolean {
        if (this.proAppConfigService.insideProtheus()){
            this.setAuthenticatedUser(true);
        } else {
            const dataToken = this.tokenManagerService.getDataToken();
            if (!dataToken || !dataToken.access_token){
                this.setAuthenticatedUser(false);
            } else if (this.tokenManagerService.isTokenExpired(dataToken.access_token)){
                this.setAuthenticatedUser(false);
            } else {
                this.setAuthenticatedUser(true);
            }
        }

      return this.authenticatedUser;
    }

    /** Dispara o evendo para o menu indicando se o usuário esta ou não atenticado, para determinar se será exibido o menu */
    setAuthenticatedUser(authenticatedUser: boolean){
        this.authenticatedUser = authenticatedUser;
        this.showMenuEmitter.emit(authenticatedUser);
    }


    /** Seta a nova linguagem selecionada na storage e dispara eventro de troca de linguagem */
    setLanguage(language: string | undefined){
        this.localStorageService.setItem(KEY_STORAGE.LANGUAGE,language,false);
        this.languageChangeEmitter.emit(language);
    }

    getLanguage(): string {
        return this.localStorageService.getString(KEY_STORAGE.LANGUAGE);
    }


    /** Reseta as configurações que estão no storage */
    restoreSettings(): void {
        //--exemplo this.localStorageService.setItem( KEY_STORAGE.HOMEFILTER, []);
    }

    /** Realiza o fechamento da aplicaçã se for via Protheus fecha a rotina e se não for Protheus retorna para a pagina de login.*/
    closeApp(): void {
        if (this.proAppConfigService.insideProtheus()) {
            this.proAppConfigService.callAppClose(true)
            this.setAuthenticatedUser(false);
            this.userService.clearSubject();
            this.advertisementsService.clearSubject();
            this.curriculumService.clearSubject();
            this.tokenManagerService.clearToken();
        } else {
            this.setAuthenticatedUser(false);
            this.tokenManagerService.clearToken();
            this.userService.clearSubject();
            this.advertisementsService.clearSubject();
            this.curriculumService.clearSubject();
            this.router.navigate(['login']);
        }
    }
}
